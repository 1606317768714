@font-face {
  font-family: 'Roboto';
  src: url('Roboto-Black-subset.woff2') format('woff2');
  font-style: normal;
  font-display: swap;
  font-weight: 900;
}

@font-face {
  font-family: 'Roboto';
  src: url('Roboto-Bold-subset.woff2') format('woff2');
  font-style: normal;
  font-display: swap;
  font-weight: 700;
}

@font-face {
  font-family: 'Roboto';
  src: url('Roboto-Medium-subset.woff2') format('woff2');
  font-style: normal;
  font-display: swap;
  font-weight: 500;
}

@font-face {
  font-family: 'Roboto';
  src: url('Roboto-MediumItalic-subset.woff2') format('woff2');
  font-style: italic;
  font-display: swap;
  font-weight: 500;
}

@font-face {
  font-family: 'Roboto';
  src: url('Roboto-Italic-subset.woff2') format('woff2');
  font-style: italic;
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: 'Roboto';
  src: url('Roboto-Regular-subset.woff2') format('woff2');
  font-style: normal;
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: 'Montserrat';
  src: url('Montserrat-Black.woff2') format('woff2');
  font-style: normal;
  font-display: swap;
  font-weight: 900;
}

@font-face {
  font-family: 'Montserrat';
  src: url('Montserrat-Bold.woff2') format('woff2');
  font-style: normal;
  font-display: swap;
  font-weight: 700;
}

@font-face {
  font-family: 'Montserrat';
  src: url('Montserrat-Medium.woff2') format('woff2');
  font-style: normal;
  font-display: swap;
  font-weight: 500;
}

@font-face {
  font-family: 'Montserrat';
  src: url('Montserrat-MediumItalic.woff2') format('woff2');
  font-style: italic;
  font-display: swap;
  font-weight: 500;
}

@font-face {
  font-family: 'Montserrat';
  src: url('Montserrat-Italic.woff2') format('woff2');
  font-style: italic;
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: 'Montserrat';
  src: url('Montserrat-Regular.woff2') format('woff2');
  font-style: normal;
  font-display: swap;
  font-weight: 400;
}
